export const MetaData = {
  "/": {
    title: "RedBeryl™",
    description: "Welcome to the trailblazer of luxury lifestyle services.",
    keywords: [""]
  },
  "/about": {
    title: "About Us | RedBeryl™",
    description: "The expert team that is behind curating experiences for you.",
    keywords: [""]
  },
  "/services": {
    title: "Services | RedBeryl™",
    description: "Access the finest experiences across the globe.",
    keywords: [""]
  },
  "/memberships": {
    title: "Membership | RedBeryl™",
    description: "Access unparalleled experiences curated just for you.",
    keywords: [""]
  },
  "/faq": {
    title: "FAQs | RedBeryl™",
    description: "Stay in the know with everything about our membership programme.",
    keywords: [""]
  },
  "/contact-us": {
    title: "Contact Us | RedBeryl™",
    description: "Connect with us to get started on your RedBeryl™ Life.",
    keywords: [""]
  },
  "/terms-and-conditions": {
    title: "Terms and Conditions | RedBeryl™",
    description: "RedBeryl™ Terms and Conditions  ",
    keywords: [""]
  },
  "/privacy-policy": {
    title: "Privacy Policy",
    description: "RedBeryl™ Privacy Policy",
    keywords: [""]
  },
  "/refunds-policy": {
    title: "Refunds Policy | RedBeryl™",
    description: "RedBeryl™ Refund Policy",
    keywords: [""]
  },
  "/supplier-code-of-conduct": {
    title: "Supplier Code of Conduct | RedBeryl™",
    description: "RedBeryl™ Supplier Code of Conduct",
    keywords: [""]
  },
  "/thankyou": {
    title: "ThankYou | RedBeryl™",
    description: "ThankYou",
    keywords: [""]
  },
  "/join": {
    title: "Join | RedBeryl™",
    description: "ThankYou",
    keywords: [""]
  },
  "/error": {
    title: "ThankYou | RedBeryl™",
    description: "ThankYou",
    keywords: [""]
  },
};

import React from "react";
import "./ThankYou.scss";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // You can change this to 'auto' for instant scrolling
  });
};

const ThankYou = () => {
  scrollToTop();

  return (
    <>
      <Header />
      <center>
        <h1 className="tyh1">
          Thank you for getting in
          <br />
          touch with us!
        </h1>

        <p className="f-7">
          Our Relationship Manager will reach out to you shortly.
        </p>

        <img
          src="https://redberyl.co/images%20only/thankyou-main.webp"
          alt="Thank You Image"
        />
        <p className="f-7 f-red">
          You're one step closer to unlocking the RedBeryl<sup>TM</sup> Life.
        </p>
      </center>
      <Footer />
    </>
  );
};

export default ThankYou;

import React, { useState } from "react";
import "./ContactUs.scss";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import { Link, useNavigate } from "react-router-dom";
import { NewContactUs } from "../../api/instance";
const ContactUs = () => {
  const navigate = useNavigate();
  const [validEmail, setValidEmail] = useState();
  const [firstName, setFirstName] = useState(false);
  const [lastName, setLname] = useState(false);
  const [email, setEmail] = useState(false);
  const [phone, setPhone] = useState(false);
  const [query, setQuery] = useState(false);
  const [hearFrom, setHfrom] = useState(false);
  const [message, setMesage] = useState(false);
  const [checkbox, setCheckBx] = useState(false);
  const [number, setNumber] = useState();

  const handleContactSubmit = async () => {
    const firstName = document.getElementById("firstName").value;
    const lastName = document.getElementById("lastName").value;
    const email = document.getElementById("email").value;
    const phone = document.getElementById("phone").value;
    const hearFrom = document.getElementById("hearFrom").value;
    const query = document.getElementById("query").value;
    const message = document.getElementById("message").value;
    const checkbox = document.getElementById("confirmCheckbox");

    const isEmailValid = isValidEmail(email);
    const isFirstNameValid = !!firstName;
    const isLastNameValid = !!lastName;
    const isPhoneValid = phone.length === 10;
    const isHearFromValid = !!hearFrom;
    const isQueryValid = !!query;
    const isMessageValid = !!message;
    const isCheckboxValid = checkbox && checkbox.checked;

    if (
      !isEmailValid ||
      !isFirstNameValid ||
      !isLastNameValid ||
      !isPhoneValid ||
      !isHearFromValid ||
      !isMessageValid ||
      !isQueryValid ||
      !isCheckboxValid
    ) {
      setValidEmail(isEmailValid ? "" : "Email is Invalid");
      setQuery(!isQueryValid);
      setFirstName(!isFirstNameValid);
      setLname(!isLastNameValid);
      setEmail(email ? "" : "Email ID is required");
      setPhone(!isPhoneValid);
      setHfrom(!isHearFromValid);
      setMesage(!isMessageValid);
      setCheckBx(!isCheckboxValid);
      return;
    }

    await NewContactUs({
      firstName,
      lastName,
      email,
      query,
      phone,
      hearFrom,
      message,
    });
    navigate("/thankyou");
  };
  const handleFirstNameChange = () => {
    setFirstName(false);
  };

  const handleLastNameChange = () => {
    setLname(false);
  };

  const handleEmailChange = () => {
    setValidEmail("");
  };

  const handleMessageChange = () => {
    setMesage(false);
  };

  const handleCheckboxChange = () => {
    setCheckBx(false);
  };

  function isValidEmail(email) {
    console.log(email);
    return /\S+@\S+\.\S+/.test(email);
  }

  const handlePhoneNumberChange = (event) => {
    setPhone(false);
    const inputPhoneNumber = event.target.value;
    const numericPhoneNumber = inputPhoneNumber.replace(/\D/g, "").slice(0, 10); // Truncate to 10 digits
    setNumber(numericPhoneNumber);
  };

  return (
    <>
      <Header />
      <section className="membership">
        <div className="accord">
          <a href="/" style={{ textDecoration: "none" }}>
            <p className="f-8">Home</p>
          </a>
          <span className="f-8">{">"}</span>
          <p className="f-8 clr-f">Contact Us</p>
        </div>

        <h1 id="font-size-heading-32">We are here for you</h1>

        <p id="font-size-text-16" className="f-7 ">
          For general questions please refer to the FAQs or email us at:{" "}
          <a className="contact-email" href="mailto:support@redberyl.co">
            <b>support@redberyl.co</b>
          </a>
        </p>

        <p id="font-size-text-16" className="f-7 ">
          For partnership queries, email us at:{" "}
          <a className="contact-email" href="mailto:partnerships@redberyl.co">
            <b>partnerships@redberyl.co</b>
          </a>
        </p>

        <p id="font-size-text-16" className="f-7 ">
          For other queries, please fill the form below and our Relationship
          Manager will get back you promptly.
        </p>

        <hr className="c-redhr" />

        <div className="form-wrapper">
          <div className="form">
            <h1 id="font-size-heading-32" className="c-red">
              Contact Us
            </h1>
            <br />
            <div>
              <div className="form-group w-12">
                <label>
                  Select Query <font style={{ color: "#93141D" }}>*</font>{" "}
                </label>
                <select required id="query">
                  <option disabled selected>
                    Please Select
                  </option>
                  <option value="Membership Query">Membership Query</option>
                  <option value="Career Queries">Career Queries</option>
                  <option value="Know About RedBeryl">
                    Know About RedBeryl™
                  </option>
                  <option value="Benefits Query">Benefits Query</option>
                  <option value="General Queries">General Queries</option>
                  <option value="Feedbacks & Suggestions">
                    Feedbacks & Suggestions
                  </option>
                  <option value="Other">Other</option>
                </select>
                {query ? (
                  <>
                    <span>Please Select a query type</span>
                  </>
                ) : (
                  <> </>
                )}
              </div>

              <div className="form-group w-12">
                <label>Salutations</label>
                <select required id="salutation">
                  <option disabled selected>
                    Please Select
                  </option>
                  <option value="Mr">Mr.</option>
                  <option value="Mrs">Mrs.</option>
                  <option value="Ms">Ms.</option>
                  <option value="Dr">Dr.</option>
                  <option value="Prof">Prof.</option>
                </select>
              </div>

              <div className="form-group w-12 row">
                <div className="form-group w-6">
                  <label>
                    First Name<font style={{ color: "#93141D" }}>*</font>
                  </label>
                  <input
                    type="text"
                    required
                    id="firstName"
                    onChange={handleFirstNameChange}
                  />
                  {firstName ? (
                    <>
                      <span>Please Fill in the First Name</span>
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
                <div className="form-group w-6">
                  <label>
                    Last Name<font style={{ color: "#93141D" }}>*</font>
                  </label>
                  <input
                    type="text"
                    required
                    id="lastName"
                    onChange={handleLastNameChange}
                  />
                  {lastName ? (
                    <>
                      <span>Please Fill in the Last Name</span>
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>

              <div className="form-group w-12">
                <div className="form-group ">
                  <label>
                    Email Address<font style={{ color: "#93141D" }}>*</font>
                  </label>
                  <input
                    type="email"
                    required
                    id="email"
                    onChange={handleEmailChange}
                  />
                  <span>{validEmail}</span>
                </div>
              </div>

              <div className="form-group w-12">
                <div className="form-group ">
                  <label>
                    Phone Number<font style={{ color: "#93141D" }}>*</font>
                  </label>
                  <input
                    type="text" // Change type to "text"
                    required
                    id="phone"
                    value={number}
                    onChange={handlePhoneNumberChange} // Add onChange event handler
                    maxLength={10}
                  />
                  {phone ? (
                    <>
                      <span>Phone Number is Invalid</span>
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>

              <div className="form-group w-12">
                <div className="form-group ">
                  <label>How did you hear about us?</label>
                  <select required id="hearFrom">
                    <option disabled>Please Select</option>
                    <option value="Word Of Mouth">Word Of Mouth</option>
                    <option value="Member Reference">Member Reference</option>
                    <option value="Online Search">Online Search</option>
                    <option value="Social Media">Social Media</option>
                    <option value="Email/Newsletters">Email/Newsletters</option>
                    <option value="Website/Blogs">Website/Blogs</option>
                    <option value="Industry Publications">
                      Industry Publications
                    </option>
                    <option value="Others">Others </option>
                  </select>
                </div>
              </div>

              <div className="form-group w-12">
                <div className="form-group ">
                  <label>
                    Let us know how we can help you...
                    <font style={{ color: "#93141D" }}>*</font>
                  </label>
                  <textarea
                    type="text"
                    id="message"
                    required
                    onChange={handleMessageChange}
                  />
                  {message ? (
                    <>
                      <span>Message is required.</span>
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>

              <div className="form-group w-12">
                <div className="form-group ">
                  <div className="checkClass">
                    <input
                      type="checkbox"
                      required
                      style={{ backgroundColor: "#93141D" }}
                      id="confirmCheckbox"
                      onChange={handleCheckboxChange}
                    />
                    <label className="bText">
                      {" "}
                      I confirm that I have read and agree with the
                      <b
                        style={{
                          color: "#93141D",
                          marginLeft: "4px",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          window.location.href = "/Terms-and-conditions";
                        }}
                      >
                        Terms & Conditions and Privacy Policy*
                      </b>
                    </label>
                  </div>
                  {checkbox ? (
                    <>
                      <span>CheckBox is Required</span>
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>

              <div className="membershipSubmit">
                <span>
                  *Indicates mandatory fields
                </span>

                <button
                  className="btn-community"
                  onClick={handleContactSubmit}
                  type="submit"
                  value="Submit"
                >
                  {" "}
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ContactUs;

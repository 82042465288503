import React from "react";
import "react-lazy-load-image-component/src/effects/blur.css";

const SingleSlider = (props) => {
  console.log("Single Slider", props.padding);
  return (
    <>
      <div className="slider">
        <img src={props.img} alt={props.img} {...props} />
        <div className="desc">
          {props.tagline !== "" ? (
            <>
              <span
                className="f-6"
                style={{ marginTop: "15px" }}
              >
                {props.tagline}{" "}
              </span>
            </>
          ) : (
            <></>
          )}

          <h1 className="font-f37">{props.heading}</h1>
          {props.p1 !== "" ? (
            <>
              <p  className="f-6">
                {props.p1}
              </p>
            </>
          ) : (
            <></>
          )}

          {props.p2 !== "" ? (
            <>
              <p className="f-6">{props.p2}</p>
            </>
          ) : (
            <></>
          )}

          {props.button === "true" ? (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <a
                  style={{
                    textDecoration: "none",
                    paddingBottom: `${
                      window.innerWidth > 720 ? props?.padding : ""
                    }`,
                  }}
                  href="/Memberships"
                >
                  <button className="btn-community">Apply Now</button>
                </a>
              </div>
            </>
          ) : (
            <>
              {props.button === "false" ? (
                <>
                  {" "}
                  <p className="f-7">
                    <b id="font-size-special-18" style={{fontFamily:"PoppinsRegular"}}>By Invitation only</b>
                  </p>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SingleSlider;

export const testimonial_Video = `https://www.youtube.com/watch?v=TSjUx90TsXw`;

export const menuItems = [
  {
    label: "HOME",
    link: "/",
  },
  {
    label: "ABOUT US",
    link: "/About",
  },
  {
    label: "SERVICES",
    link: "/Services",
  },
  {
    label: "MEMBERSHIP",
    link: "/memberships",
  },
  {
    label: "FAQs",
    link: "/FAQ",
  },
  {
    label: "CONTACT US",
    link: "/Contact-Us",
  },
];

import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "./Slider.scss";
import { Swiper, SwiperSlide } from "swiper/react";

import { FreeMode, Pagination, Keyboard, Mousewheel } from "swiper/modules";
import SingleSlider from "./SingleSlider";

const SliderCountry = () => {
  let [count, setCount] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let width = window.innerWidth;

    if (width > 1100) {
      setCount(4);
      setLoading(false);
    } else if (width > 768) {
      setCount(3);
      setLoading(false);
    } else if (width > 480) {
      setCount(2);
      setLoading(false);
    } else {
      setCount(1.2);
      setLoading(false);
    }
  }, []);

  return (
    <>
      {loading === false ? (
        <>
          <Swiper
            slidesPerView={count}
            spaceBetween={30}
            keyboard={{
              enabled: true,
            }}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            mousewheel={true}
            modules={[FreeMode, Pagination, Keyboard, Mousewheel]}
            className="mySwiper cty"
          >
            <SwiperSlide>
              <SingleSlider
                p1="Your dedicated relationship manager
                provides personalised and perceptive
                support, ensuring your needs are met
                with precision and care, making every
                interaction a delightful experience.
                "
                heading="Dedicated Relationship Manager"
                className="countrySlider"
                img="https://redberyl.co/images%20only/services/Homepage%20services_Dedicated%20RM.webp"
              />
            </SwiperSlide>
            <SwiperSlide>
              <SingleSlider
                p1="Enjoy seamless luxury with personal concierge®
                services across 40+ countries, ready to fulfil your
                every request with unwavering dedication and
                utmost precision, making every moment an
                unrivalled experience.
                "
                heading="24/7 Concierge"
                className="countrySlider"
                img="https://redberyl.co/images%20only/services/Homepage%20services_24-7%20Concierge.webp"
              />
            </SwiperSlide>

            <SwiperSlide>
              <SingleSlider
                p1="Let our skillful Dream Weavers tailor a tapestry of your
                desires, meticulously crafting your personalised
                RedBerylTM list, where dreams are transformed into
                tangible realities, and each experience becomes a
                masterpiece, designed exclusively for you.
                "
                heading="Dream Weaver"
                className="countrySlider"
                img="https://redberyl.co/images%20only/services/Homepage%20services_Dream%20weaver.webp"
              />
            </SwiperSlide>
            <SwiperSlide>
              <SingleSlider
                p1="Enjoy a myriad of extraordinary events and meet-ups,
                with privileged access to coveted gatherings, celebrity
                encounters, art soirées, and wine tastings, immersing
                you in a world of enchantment.
                "
                heading="Sports, Events & Entertainment"
                className="countrySlider"
                img="https://redberyl.co/images%20only/services/Homepage-services_Sports_-events.webp"
              />
            </SwiperSlide>

            <SwiperSlide>
              <SingleSlider
                p1="Embark on extraordinary journeys, adorned with exclusive
                member benefits, access to over 15,000 hotels worldwide
                and captivating itineraries tailored to your preferences.
                "
                heading="Curated Journeys"
                className="countrySlider"
                img="https://redberyl.co/images%20only/services/Homepage%20services_Curated%20Journeys.webp"
              />
            </SwiperSlide>
            <SwiperSlide>
              <SingleSlider
                p1="Indulge in an exceptional culinary journey, savouring delights from the finest, where celebrated chefs craft a symphony of flavours, textures, and aromas, elevating each moment."
                heading="Finest Dining"
                className="countrySlider"
                img="https://redberyl.co/images%20only/services/Homepage%20services_Finest%20Dining.webp"
              />
            </SwiperSlide>

            <SwiperSlide>
              <SingleSlider
                p1="Elevate your travel with our airport assistance
                services, spanning the globe for seamless journeys;
                our dedicated team provides VIP meet and greet,
                whisking you through check-in, security, and customs.
                "
                heading="VIP airport Assist"
                className="countrySlider"
                img="https://redberyl.co/images%20only/services/Homepage-services_Vip-airport.webp"
              />
            </SwiperSlide>
            <SwiperSlide>
              <SingleSlider
                p1="Experience the epitome of exclusivity and indulgence
                as you traverse the world, where luxury cars,
                limousines, private jets, and yachts await to transport
                you to your destinations, ensuring every moment is an
                opulent celebration of sophistication and refinement.
                
                "
                heading="Elite Mobility"
                className="countrySlider"
                img="https://redberyl.co/images%20only/services/Homepage%20services_Elite%20Mobility.webp"
              />
            </SwiperSlide>

            <SwiperSlide>
              <SingleSlider
                p1="Enter an exclusive world of super-premium,
                members-only nightclubs and social clubs worldwide,
                where you'll step into a realm of glamour and
                prestige, mingling with like-minded individuals
                making each night an unforgettable celebration.                
                "
                heading="Exclusive Clubs"
                className="countrySlider"
                img="https://redberyl.co/images%20only/services/Homepage%20services_Exclusive%20Clubs.webp"
              />
            </SwiperSlide>
          </Swiper>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SliderCountry;
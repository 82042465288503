import React from "react";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <>

      <Header />
      <section className="refunds">
        <div className="accord">
          <a href="/" style={{ textDecoration: "none" }}>
            <p className="f-8">Home</p>
          </a>
          <span className="f-8">{">"}</span>
          <p className="f-8 clr-f">Privacy Policy</p>
        </div>

        <h3>RedBeryl™ Lifestyle Services Private Limited Privacy notice.</h3>

        <p>
          RedBeryl™ respects your privacy and is committed to protecting your
          personal data. This privacy notice will inform you about how we
          collect, use, store, and protect your personal data. It also outlines
          your privacy rights and explains how the law protects you.
        </p>

        <p>
          This privacy notice is presented in a layered format, allowing you to
          navigate directly to the specific sections mentioned below. We
          recommend referring to the glossary section for the definitions of key
          terms used throughout this notice.
        </p>

        <p>
          <span className="f-red">
            Important Information and Who We Are
            <br />
          </span>
          How We Collect Your Personal Data
          <br />
          The Types of Personal Data We Collect
          <br />
          How We Use Your Personal Data
          <br />
          Disclosures of Your Personal Data
          <br />
          International Transfers
          <br />
          Data Security
          <br />
          Data Retention
          <br />
          Your Legal Rights
          <br />
          Glossary
        </p>

        <hr className="c-red" />

        <h3>Important Information And Who We Are </h3>

        <h3>Purpose of this privacy notice:</h3>

        <p>
          This privacy notice aims to provide you with information on how
          RedBeryl™ collects and processes your personal data. It applies to
          the data we collect through our website, as well as through our
          services. This notice is important, so please read it carefully.
        </p>

        <hr className="c-red" />
        <h3>Controller:</h3>
        <p>
          RedBeryl™ Lifestyle Services Private Limited is the data controller
          responsible for this website and the processing of your personal data.
          If you have any questions regarding this privacy notice or your
          personal data, including requests to exercise your legal rights,
          please contact us using the details provided below.
        </p>

        <hr className="c-red" />
        <h3>Contact details: </h3>

        <p>
          <b>Full Name Of Legal Entity:</b>
          <br />
          RedBeryl™ Lifestyle Services Private Limited
        </p>

        <p>
          <b>Name or title of Data Protection Officer:</b>
          <br />
          RedBeryl™ Lifestyle Services Private Limited
        </p>

        <p>
          <b>Email Address:</b>
          <br />
          support@redberyl.co
        </p>

        <p>
          <b>Postal Address:</b>
          <br />
          First Floor, H.No. 93, Nav Jeevan Vihar, Malviya Nagar, New Delhi,
          South Delhi, Delhi, 110017
        </p>

        <p>
          <b>Telephone Number:</b>
          <br />
          +91 8108135656
        </p>

        <p>
          We encourage you to contact us regarding any concerns or issues you
          may have.
        </p>

        <hr className="c-red" />

        <h3>
          Changes to the privacy notice and your duty to inform us of changes:
        </h3>

        <p>
          We may update this privacy notice from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. We will notify you of any material changes in advance, either
          by email or by prominently posting a notice on our website.
        </p>

        <p>
          It is important that the personal data we hold about you is accurate
          and up to date. Please inform us promptly if any of your personal data
          changes during your relationship with us.
        </p>

        <hr className="c-red" />

        <h3>Third-party links</h3>

        <p>
          We may update this privacy notice from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. We will notify you of any material changes in advance, either
          by email or by prominently posting a notice on our website.
        </p>

        <p>
          It is important that the personal data we hold about you is accurate
          and up to date. Please inform us promptly if any of your personal data
          changes during your relationship with us
        </p>

        <p>
          Our website may include links to third-party websites, plugins, or
          applications. Clicking on those links or enabling those connections
          may allow third parties to collect or share data about you. We do not
          control these third-party websites and are not responsible for their
          privacy statements. When you leave our website, we encourage you to
          read the privacy notice of every website you visit
        </p>

        <h3>How we collect your personal data</h3>

        <p>
          We use various methods to collect data from and about you, including:
        </p>

        <p>
          <b>Direct interactions: </b>You may provide your personal data to us
          when you interact with us directly, such as when you fill in forms,
          correspond with us through email or post, or communicate with us in
          any other way. Automated technologies or interactions: When you visit
          our website, we may collect Technical Data about your device, browsing
          actions, and patterns. We collect this data through cookies, server
          logs, and other similar technologies. Please refer to our cookie
          policy for more information. Third-party sources: We may receive
          personal data about you from various third-party sources, such as our
          business partners, service providers, or publicly available sources.
        </p>

        <h3>The types of personal data we collect </h3>

        <p>
          Personal data, or personal information, refers to any information that
          identifies or can be used to identify an individual. We may collect
          and process various types of personal data about you, including:
        </p>

        <p>
          <b>Identity Data:</b> This includes your name, username, title, and
          date of birth.{" "}
        </p>

        <p>
          <b>Contact Data: </b>This includes your email address, postal address,
          and telephone numbers.{" "}
        </p>

        <p>
          <b>Financial Data: </b>This includes your bank account and payment
          card details.{" "}
        </p>

        <p>
          <b>Transaction Data:</b> This includes details about payments and
          purchases made by you.{" "}
        </p>

        <p>
          <b>Technical Data:</b> This includes your IP address, browser type and
          version, time zone setting and location, browser plug-in types and
          versions, operating system and platform, and other technology on the
          devices you use to access our website.
        </p>

        <p>
          <b>Profile Data: </b>This includes your username and password,
          purchases or orders made by you, your interests, preferences, and
          feedback.{" "}
        </p>

        <p>
          <b>Usage Data:</b> This includes information about how you use our
          website and services.{" "}
        </p>

        <p>
          Marketing and Communications Data: This includes your preferences in
          receiving marketing materials from us and our third parties, as well
          as your communication preferences.
        </p>

        <p>
          We may also collect and process special categories of personal data,
          such as information about your health, religious beliefs, or other
          sensitive information. However, we will only process such data if
          there is a lawful basis and in compliance with applicable data
          protection laws.
        </p>

        <h3>How we use your personal data </h3>
        <p>
          We will only use your personal data when the law allows us to do so.
          Generally, we use your personal data for the following purposes:
        </p>

        <p>
          <b>To fulfil a contract:</b> We may process your personal data in
          order to provide you with our services or fulfil a contract we have
          with you.{" "}
        </p>

        <p>
          <b>For our legitimate interests:</b> We may process your personal data
          for our legitimate interests or the legitimate interests of a third
          party. This includes improving our services, conducting research and
          analysis, preventing fraud, and ensuring network and information
          security.{" "}
        </p>

        <p>
          <b>To comply with legal obligations:</b> We may process your personal
          data to comply with legal or regulatory obligations that we are
          subject to.{" "}
        </p>

        <p>
          <b>With your consent:</b> In some cases, we may rely on your consent
          to process your personal data, such as for direct marketing purposes.
          You have the right to withdraw your consent at any time by contacting
          us
        </p>

        <p>
          Please note that we may process your personal data for more than one
          lawful basis, depending on the specific purpose for which we are using
          your data.
        </p>

        <h3>Disclosures of your personal data </h3>
        <p>We may share your personal data with the following parties:</p>

        <p>
          <b>Internal Third Parties:</b> Other companies within the RedBeryl™
          group that act as joint controllers or processors, and are located
          within India.{" "}
        </p>

        <p>
          <b>External Third Parties:</b> Third-party service providers that act
          as processors and provide services such as IT and system
          administration. Third parties to whom we may choose to sell, transfer,
          or merge parts of our business or assets. In the event of a business
          sale, merger, or acquisition, your personal data may be disclosed or
          transferred to the relevant third parties involved. We require all
          third parties to respect the security of your personal data and to
          treat it in accordance with the law. We only allow them to process
          your personal data for specified purposes and in accordance with our
          instructions
        </p>

        <h3>International transfers </h3>
        <p>
          We may transfer your personal data to countries outside of India.
          These transfers may be necessary for the purposes outlined in this
          privacy notice, such as for the provision of services or for our
          legitimate interests. When we transfer personal data outside of India,
          we ensure appropriate safeguards are in place to protect your personal
          data, as required by applicable data protection laws.
        </p>

        <h3>Data security</h3>
        <p>
          We have implemented appropriate security measures to protect your
          personal data and prevent unauthorized access, disclosure, alteration,
          or destruction. We regularly review our security procedures to ensure
          they remain effective and aligned with industry standards.
        </p>

        <h3>Data retention</h3>
        <p>
          We will retain your personal data for as long as necessary to fulfil
          the purposes for which we collected it, including any legal,
          accounting, or reporting requirements. The retention period may vary
          depending on the nature of the data and the purposes for which it is
          processed
        </p>

        <p>
          Once the retention period expires, we will securely delete or
          anonymize your personal data, unless we are required to retain it for
          a longer period under applicable laws.
        </p>

        <h3>Your legal Rights</h3>
        <p>
          Once the retention period expires, we will securely delete or
          anonymize your personal data, unless we are required to retain it for
          a longer period under applicable laws.
        </p>

        <p>
          <b>Right to access:</b> You have the right to request access to the
          personal data we hold about you.{" "}
        </p>

        <p>
          <b>Right to rectification:</b> You can request that we correct any
          inaccurate or incomplete personal data we hold about you.{" "}
        </p>

        <p>
          <b>Right to erasure:</b> You have the right to request the erasure of
          your personal data under certain circumstances.
        </p>

        <p>
          <b>Right to object:</b> You can object to the processing of your
          personal data if we are relying on legitimate interests as the legal
          basis for processing.
        </p>

        <p>
          <b>Right to restriction of processing:</b> You have the right to
          request the restriction of processing of your personal data under
          certain circumstances.{" "}
        </p>

        <p>
          <b>Right to data portability:</b> You can request the transfer of your
          personal data to another party or receive it in a structured, commonly
          used, machine-readable format.
        </p>

        <p>
          <b>Right to withdraw consent:</b> If we rely on your consent to
          process your personal data, you have the right to withdraw that
          consent at any time.{" "}
        </p>

        <p>
          To exercise any of these rights, please contact us using the contact
          details provided in this privacy notice. We will respond to your
          request within the timeframes required by applicable laws.
        </p>

        <p>
          Please note that we may need to verify your identity before fulfilling
          certain requests. We may also refuse to comply with certain requests
          if they are unfounded, excessive, or if they infringe on the rights of
          others.
        </p>

        <hr className="c-red" />

        <h3>Glossary</h3>

        <p>
          The glossary section provides definitions of key terms used throughout
          this privacy notice. Some of the key terms explained in the glossary
          are:
        </p>

        <p>
          <b>Lawful Basis:</b> The legal basis or bases on which we rely to
          process personal data, including legitimate interests, performance of
          a contract, compliance with legal obligations, and consent.{" "}
        </p>

        <p>
          <b>Internal Third Parties:</b> Other companies within the RedBeryl™
          group acting as joint controllers or processors.{" "}
        </p>

        <p>
          <b>External Third Parties:</b> Third-party service providers acting as
          processors who provide IT and system administration services.
        </p>

        <p>
          <b>Your Legal Rights:</b> The rights you have under data protection
          laws, including the right to access, rectification, erasure, object,
          restriction of processing, data portability, and withdrawal of
          consent.
        </p>

        <p>
          Please refer to the glossary section in the original privacy notice
          for a complete list of definitions.
        </p>
      </section>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;

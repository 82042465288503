import React from "react";
// import youtube from "../assets/images/youtube.svg";
import insta from "../assets/images/insta.svg";
import linkedin from "../assets/images/linkedin.svg";
import "../assets/scss/footer.scss";
import yt from "../assets/images/icons8-youtube.svg";
import fb from "../assets/images/whatsapp.svg";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const footerIcons = [
  [insta, fb, linkedin, yt],
  [
    "https://www.instagram.com/redberyllife/",
    "https://www.facebook.com/profile.php?id=100095438926984",
    "https://www.linkedin.com/in/redberyl/",
    "https://www.youtube.com/@theredberyllife",
  ],
]; //change social links here

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="w-80">
          <a href="/">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/logoWhite.svg"
              alt="logo"
            />
          </a>
          <hr className="line" />
          <p className="f-8">WE ARE SOCIAL</p>
          <ul>
            {footerIcons[0].map((icon, link) => (
              <li key={icon}>
                <a href={footerIcons[1][link]} >
                  <LazyLoadImage
                    effect="blur"
                    src={icon}
                    className="contactIcon"
                    alt={icon}
                  />
                </a>
              </li>
            ))}
          </ul>
          <hr className="line" />
          <div className="footer-text f-8" >
            <a href="/About">
              <p className="" style={{ Width: "80%" }}>
                About Us
              </p>
            </a>
            <a href="/Services">
              <p className="mLeft" style={{ Width: "80%" }}>
                Services
              </p>
            </a>

            <a href="/memberships">
              <p className="mLeft" style={{ Width: "80%" }}>
                Membership
              </p>
            </a>
            <a href="/FAQ">
              <p className="mLeft" style={{ Width: "90%" }}>
                FAQs
              </p>
            </a>
            <a href="/Contact-Us">
              <p className="mLeft" style={{ Width: "90%" }}>
                Contact Us
              </p>
            </a>
          </div>
          <hr className="line" />
          <div className="footer-text f-8">
            <a href="/Terms-and-conditions">
              <p className="" style={{ Width: "90%" }}>
                Terms & Conditions
              </p>
            </a>
            <a href="/Privacy-Policy">
              <p className="" style={{ Width: "90%" }}>
                Privacy Policy
              </p>
            </a>

            <a href="/Refunds-Policy">
              <p className="" style={{ Width: "90%" }}>
                Refunds Policy
              </p>
            </a>
            <a href="/Supplier-Code-Of-Conduct">
              <p className="" style={{ Width: "90%" }}>
                Supplier Code of Conduct
              </p>
            </a>
          </div>
          <p className="t-align-left ">RedBeryl™ 2023 All Rights Reserved</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import React from "react";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import "./About.scss";
import values from "../../Mock/Values";
import { LazyLoadImage } from "react-lazy-load-image-component";

const About = () => {
  return (
    <>
      <Header />
      <section className="about">
        <div className="accord">
          <a href="/" style={{ textDecoration: "none" }}>
            <p className="f-8">Home</p>
          </a>
          <span className="f-8">{">"}</span>
          <p className="f-8 clr-f">About Us</p>
        </div>
        <div id="header-img-wrapper" className="d-flex">
          <LazyLoadImage
            effect="blur"
            src="https://redberyl.co/images%20only/Desktop-Header-images.jpg"
            alt="Abt-img"
            className="abt-img header-img"
          />
          <section className="txt">
            <h1 id="font-size-heading-32" className="font-f37">
              About Us
            </h1>
            <br />

            <p className="f-8">
              RedBeryl™ is a premier luxury lifestyle management company
              dedicated to curating exceptional journeys and personalized
              services for our esteemed members.
            </p>

            <p className="f-8">
              Offering unparalleled access to the finest experiences, exclusive
              events, and a dedicated team of lifestyle experts, our passion for
              excellence paves your gateway to an elevated life.
            </p>
          </section>
        </div>

        <hr className="c-red" />
        <h1 id="font-size-heading-32" className="font-f37">
          Brand Story
        </h1>

        <p className="f-8">
          At RedBeryl™, we believe that life should be a collection of
          remarkable moments.
        </p>

        <p className="f-8">
          Our story is rooted in the vision of our founder, Manoj Adlakha, who
          sought to create a platform that transcends the ordinary. Driven by
          his passion to bring an impeccable membership service in India, Manoj
          set out to curate bespoke experiences that leave a lasting impression
          to those with access.
        </p>

        <hr className="c-red" />
        <h1 id="font-size-heading-32" className="font-f37">
          Our Core Values
        </h1>

        <p id="font-size-text-16" className="f-8">
          Our core values serve as the guiding principles behind everything we
          do:
        </p>
        <div className="values-wrapper">
          {values.map((v, i) => (
            <section className="values" key={i}>
              <div className="valueImg">
                <LazyLoadImage
                  effect="blur"
                  src={v.img}
                  alt="value"
                  className="test"
                />
              </div>
              <div className="text">
                <h2 id="font-size-subheading-28">{v.headline}</h2>
                <p id="font-size-text-16">{v.text}</p>
              </div>
            </section>
          ))}
        </div>
        <hr className="c-red" />
        <h1 id="font-size-heading-32" className="font-f37">
          Our Team
        </h1>

        <p className="f-8">
          At RedBeryl™, a passionate and experienced team strives to create
          exceptional experiences. With diverse expertise in luxury hospitality,
          travel, events, and personal concierge services, they are committed to
          making your dreams a reality.
        </p>

        <section className=" grid-container">
          <div className="team">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/Manoj.jpg"
              alt="member"
              style={{ borderRadius: "10px 10px 0px 0px" }}
            />
            <div className="p-10" style={{ borderRadius: "0 0 10px 10px" }}>
              <h1 id="font-size-heading-32" className="font-f37">
                Manoj Adlakha
              </h1>
              <p className="f-8 font-italic">
                Founder & Chief Executive Officer
              </p>
              <br />
              <p className="f-8">
                Manoj Adlakha is an accomplished business leader with an
                illustrious career spanning over three decades with renowned
                organisations like American Express, Visa, and Yes Bank. A
                Chartered Accountant with a BCom Honors degree from SRCC, Manoj
                honed his leadership skills through an Executive Leadership
                Program at Duke University. As the Senior VP and Head of
                International Marketing at American Express for over 30 years,
                he played a vital role in driving the organisation towards
                success. He spearheaded Amex India as CEO, earning eight
                consecutive years of recognition as India's Most Respected
                Credit Brand. At RedBeryl, he brings his vast knowledge,
                strategic planning and customer-centric expertise to deliver
                exceptional luxury experiences and steer the company's growth as
                the Founder and CEO.
              </p>
            </div>
          </div>

          <div className="team">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/Anjali.jpg"
              alt="member"
              style={{ borderRadius: "10px 10px 0px 0px" }}
            />
            <div className="p-10" style={{ borderRadius: "0 0 10px 10px" }}>
              <h1 id="font-size-heading-32" className="font-f37">
                Anjali Dubey
              </h1>
              <p className="f-8 font-italic">Chief Operating Officer</p>
              <br />
              <p className="f-8">
                With over 20 years of corporate experience in top MNCs like
                American Express, Genpact, and IBM, Anjali is an expert in
                customer experience, organisational development and business
                transformation. She operated a successful consulting business
                specialising in Content Development and Learning & Development
                (L&D). She holds double Master's degrees in Arts and is
                certified in Instructional Design, Content Writing & Marketing,
                trained in Lean and Six Sigma methodology.
              </p>
            </div>
          </div>

          <div className="team">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/Nidhi.jpg"
              alt="member"
              style={{ borderRadius: "10px 10px 0px 0px" }}
            />
            <div className="p-10" style={{ borderRadius: "0 0 10px 10px" }}>
              <h1 id="font-size-heading-32" className="font-f37">
                Nidhi Seth
              </h1>
              <p className="f-8 font-italic">Chief Marketing Officer</p>
              <br />
              <p className="f-8">
                With 25+ years of experience and an MBA degree, dual
                specialising in Finance and Marketing, Nidhi has managed HNI
                customers and Premium partners, specialising in Innovative
                Marketing, Experiential Marketing & Influencer Marketing. She
                has worked with American Express, Genpact, and Development Bank
                of Singapore in India, and was a Board Advisor to National
                Luxury Council. Nidhi has been awarded the "Super Women Achiever
                Award" and "Exceptional Woman of Excellence Award.
              </p>
            </div>
          </div>

          <div className="team">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/Mohita.jpg"
              alt="member"
              style={{ borderRadius: "10px 10px 0px 0px" }}
            />
            <div className="p-10" style={{ borderRadius: "0 0 10px 10px" }}>
              <h1 id="font-size-heading-32" className="font-f37">
                Mohita Mhatre
              </h1>
              <p className="f-8 font-italic">VP Acquisition & Sales, West</p>
              <br />
              <p className="f-8">
                Mohita is a postgraduate in marketing and e-commerce with 20
                years of experience in operations, sales and customer service in
                premium space, training colleagues. She has worked with
                companies like Heavens Portfolio, American Express, ICICI Bank
                and Standard Chartered Bank. She is exceptionally talented in
                forging key partnerships, sales and marketing management. She is
                a sportsperson and is passionate about fitness. She runs, swims,
                does Yoga and loves travelling.
              </p>
            </div>
          </div>

          <div className="team">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/Puneet.jpg"
              alt="member"
              style={{ borderRadius: "10px 10px 0px 0px" }}
            />
            <div className="p-10" style={{ borderRadius: "0 0 10px 10px" }}>
              <h1 id="font-size-heading-32" className="font-f37">
                Puneet Bhardwaj{" "}
              </h1>
              <p className="f-8 font-italic">
                VP Acquisition & Sales, North & East
              </p>
              <br />
              <p className="f-8">
                Experienced professional with 18 years of experience in Banking,
                Credit Cards, customer relationship management (CRM), UHNI
                connects, contact centers and luxury space. An MBA from IMT
                Ghaziabad and a Graduate from DU, Puneet has worked with
                organisations like Pernod Ricard India, American Express,
                TravelPort, and IBM. Cars &amp; bikes, travel, movies &amp;
                music are his passions.
              </p>
            </div>
          </div>

          <div className="team">
            <LazyLoadImage
              effect="blur"
              src="https://redberyl.co/images%20only/Aamith.jpg"
              alt="member"
              style={{ borderRadius: "10px 10px 0px 0px" }}
            />
            <div className="p-10" style={{ borderRadius: "0 0 10px 10px" }}>
              <h1 id="font-size-heading-32" className="font-f37">
                Aamith Menon{" "}
              </h1>
              <p className="f-8 font-italic">VP Acquisition & Sales, South</p>
              <br />
              <p className="f-8">
                A lifelong dreamer and learner of life, Aamith is on a mission
                to disrupt the status quo and redefine industry standards. He
                has previously worked with Lifesight Tech, Getit
                Infomedia/Askme, Hero Group, and Justdial among others. He
                combines his love for customer experience journeys and digital
                transformation to create new experiences. In his spare time, you
                will find him exploring emerging technologies, travelling and
                satiating his food cravings.
              </p>
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </>
  );
};

export default About;

const values = [
  {
    img: "https://redberyl.co/images%20only/Asset%201.webp",
    headline: "Exclusivity",
    text: "Unique experiences that are tailored to each member's preferences.",
  },

  {
    img: "https://redberyl.co/images%20only/Asset%202.webp",
    headline: "Personalization",
    text: "Unforgettable moments that reflect the individuality of our members.",
  },
  {
    img: "https://redberyl.co/images%20only/Asset%203.webp",
    headline: "Attention to Detail",
    text: "Meticulous attention to detail is at the heart of our service.",
  },
  {
    img: "https://redberyl.co/images%20only/Asset%204.webp",
    headline: "Exceptional Service",
    text: "Dedicated team to cater to your needs is available round the clock.",
  },
];

export default values;

import React from "react";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import "./TC.scss";
import Terms from "../../Mock/TermsConditions";
import { Helmet } from "react-helmet";

const TermsConds = () => {
  return (
    <>
    <Header />
      <section className="terms-conds">
        <div className="accord">
          <a href="/" style={{ textDecoration: "none" }}>
            <p className="f-8">Home</p>
          </a>
          <span className="f-8">{">"}</span>
          <p className="f-8 clr-f">Terms And Conditions</p>
        </div>

        {Terms.map((t, i) => (
          <>
            <div className="tc">
              <div className="heading">
                <p>{i + 1}.</p>
                <p style={{color:"#93141D"}}>{t.heading}</p>
              </div>
              <ul>
                {t.conds.map((c, index) => (
                  <>
                    <li>
                      <p className="font-f37">
                        {i + 1}.{index + 1}
                      </p>
                      <p>{c.txt}</p>
                    </li>
                  </>
                ))}
              </ul>
            </div>
          </>
        ))}
      </section>
      <Footer />
    </>
  );
};

export default TermsConds;

import React, { useState } from "react";
import "./join.scss";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { PayNow, baseURL } from "../../api/instance";
import { Amount } from "../../data/AmountData";
import { dynamicTexts } from "./dynamicText";

const Join = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [validEmail, setValidEmail] = useState();
  const [firstName, setFirstName] = useState(false);
  const [lastName, setLname] = useState(false);
  const [email, setEmail] = useState(false);
  const [phone, setPhone] = useState(false);
  const [checkbox, setCheckBx] = useState(false);
  const [number, setNumber] = useState();

  // const handleChange = (e) => {
  //   const result = e.target.value.replace(/\D/g, "");

  //   setNumber(result);
  // };

  const handlePaymentProcess = async () => {
    const firstName = document.getElementById("firstName").value;
    const lastName = document.getElementById("lastName").value;
    const email = document.getElementById("email").value;
    const phone = document.getElementById("phone").value;
    const checkbox = document.getElementById("confirmCheckbox");
    const amount = Amount.hasOwnProperty(params.propertyName)
      ? Amount[params.propertyName]
      : 714200;
  
    const isEmailValid = isValidEmail(email);
    const isFirstNameValid = !!firstName;
    const isLastNameValid = !!lastName;
    const isPhoneValid = phone.length === 10;
    const isCheckboxValid = checkbox && checkbox.checked;
  
    if (
      !isEmailValid ||
      !isFirstNameValid ||
      !isLastNameValid ||
      !isPhoneValid ||
      !isCheckboxValid
    ) {
      setValidEmail(isEmailValid ? "" : "Email is Invalid");
      setFirstName(!isFirstNameValid);
      setLname(!isLastNameValid);
      setEmail(email ? "" : "Email ID is required");
      setPhone(!isPhoneValid);
      setCheckBx(!isCheckboxValid);
      return;
    }
  
    const { data } = await PayNow({
      firstName,
      lastName,
      email,
      phone,
      amount,
    });
    if (data.success) {
      return (window.location.href = `${baseURL}/pay/${data.message}`);
    }
  };
  

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handlePhoneNumberChange = (event) => {
    const inputPhoneNumber = event.target.value;
    const numericPhoneNumber = inputPhoneNumber.replace(/\D/g, ""); // Remove non-numeric characters
    setNumber(numericPhoneNumber);
  };
  return (
    <>
      <Header />
      <section className="join">
        <div className="accord">
          <a href="/" style={{ textDecoration: "none" }}>
            <p className="f-8">Home</p>
          </a>
          <span className="f-8">{">"}</span>
          <p className="f-8 clr-f">Join</p>
        </div>

        <div className="test">
          <div>
          <h1 className="center">
            Your Personalised membership card awaits you
            
          </h1>
          <br />
          <span className="center">
            {dynamicTexts[params.propertyName] || (
              <div>
                <font className="center">Details of you membership :</font>
                <p>One time Joining Fee - Rs. 500,000/-</p>
                <p>Annual Recurring Fee - Rs. 190,000/-</p>
                <br />
                <p>
                  Please fill the form below and proceed to pay the balance
                  amount of Rs. 6,90,000 plus 18% GST less Rs. 1,00,000 booking
                  amount.
                </p>
                <p>
                  <b>Total Payable: Rs. 7,14,200 </b>
                </p>
              </div>
            )}
          </span>
          </div>
        </div>

        <hr className="c-redhr" />

        <div className="test">
          <div className="form">
            <p className="t-center f-7 c-red">*All Fields are mandatory</p>
            <div>
              <div className="form-group w-12 row">
                <div className="form-group w-6">
                  <label>
                    First Name<font style={{ color: "#93141D" }}>*</font>
                  </label>
                  <input type="text" required id="firstName" />
                  {firstName ? (
                    <>
                      <span>Please Fill in the First Name</span>
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
                <div className="form-group w-6">
                  <label>
                    Last Name<font style={{ color: "#93141D" }}>*</font>
                  </label>
                  <input type="text" required id="lastName" />
                  {lastName ? (
                    <>
                      <span>Please Fill in the Last Name</span>
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>

              <div className="form-group w-12">
                <label>
                  Email Address<font style={{ color: "#93141D" }}>*</font>
                </label>
                <input type="email" required id="email" />
                <span>{validEmail}</span>
              </div>

              <div className="form-group w-12">
                <div className="form-group ">
                  <label>
                    Phone Number<font style={{ color: "#93141D" }}>*</font>
                  </label>
                  <input
                    type="text" // Change type to "text"
                    required
                    id="phone"
                    value={number}
                    onChange={handlePhoneNumberChange} // Add onChange event handler
                  />
                  {phone ? (
                    <>
                      <span>Phone Number is Invalid</span>
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>

              <div className="form-group w-12">
                <div className="checkClass">
                  <input
                    type="checkbox"
                    required
                    style={{ backgroundColor: "#93141D" }}
                    id="confirmCheckbox"
                  />
                  <label className="bText">
                    {" "}
                    I confirm that I have read and agree with the
                    <b
                      style={{
                        color: "#93141D",
                        marginLeft: "4px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.location.href = "/Terms-and-conditions";
                      }}
                    >
                      Terms & Conditions and Privacy Policy*
                    </b>
                  </label>
                </div>
                {checkbox ? (
                  <>
                    <span>CheckBox is Required</span>
                  </>
                ) : (
                  <> </>
                )}
              </div>

              <br />

              <div className="membershipSubmit">
              <input
                onClick={handlePaymentProcess}
                type="submit"
                value="Proceed to Payment"
                style={{ color: "#000" }}
              />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Join;

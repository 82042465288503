import React, { useState, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './PdfRender.scss';

// Configure PDF.js worker URL to avoid CORS issues
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfRender = () => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isOpen, setIsOpen] = useState(false);

    const [initialWidth, setInitialWidth] = useState(null);
    console.log("PdfRender is called", window.innerWidth)

    const setPdfSize = () => {
        const newWidth = window.innerWidth > 600 ? 500 : window.innerWidth - window.innerWidth / 10;
        setInitialWidth(newWidth);
    };

    const openPopup = () => {
        setIsOpen(true);
        setPdfSize();
    };

    const handleClose = () => {
        setIsOpen(false);
    };


    const handlePageChange = (newPage) => {
        setPageNumber(newPage);
    };

    return (
        <div>
            <button className='btn-black-value' onClick={openPopup}>Black Value Calculator</button>
            {isOpen && (
                <div className="pdf-popup">
                    <div className="pdf-popup-content">
                        <button onClick={handleClose} className="btn-community close-button"> X </button>
                        <Document file="Black_Value_Calculator.pdf" onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                            <Page
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                customTextRenderer={false}
                                pageNumber={pageNumber}
                                width={initialWidth} />
                        </Document>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PdfRender;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FAQ from "./components/FAQ/FAQ";
import About from "./components/AboutUs/About";
import TermsConds from "./components/T&C/TermsConds";
import ContactUs from "./components/ContactUs/ContactUs";
import PrivacyPolicy from "./components/Privacy-Policy/PrivacyPolicy";
import SCOC from "./components/SCOC/SCOC";
import Refunds from "./components/Refunds/Refunds";
import Services from "./components/Services/Services";
import Membership from "./components/Membership/Membership";
import Error from "./components/Error/Error";
import ThankYou from "./components/ThankYou/ThankYou";
import Join from "./components/Join/join";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route exact path="/FAQ" element={<FAQ />} />
        <Route exact path="/About" element={<About />} />
        <Route exact path="/Terms-and-conditions" element={<TermsConds />} />
        <Route exact path="/Contact-Us" element={<ContactUs />} />
        <Route exact path="/Privacy-Policy" element={<PrivacyPolicy />} />
        <Route exact path="/Supplier-Code-Of-Conduct" element={<SCOC />} />
        <Route exact path="/Refunds-Policy" element={<Refunds />} />
        <Route exact path="/Services" element={<Services />} />
        <Route exact path="/memberships" element={<Membership />} />
        <Route exact path="/Join/:propertyName?" element={<Join />} />
        <Route exact path="/ThankYou" element={<ThankYou />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
);